exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-brand-tsx": () => import("./../../../src/pages/brand.tsx" /* webpackChunkName: "component---src-pages-brand-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-social-tsx": () => import("./../../../src/pages/social.tsx" /* webpackChunkName: "component---src-pages-social-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */)
}

